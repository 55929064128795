import template from "./template.html";

export default {
    name: "SCalculatorImg",
    template,
    props: {
        src: {
            type: String,
            default: ''
        }
    }
}
