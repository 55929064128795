<template>
  <b-container>
    <hr class="my-1">
    <b-row>
      <b-col cols="2">
        <b-badge variant="primary">tabs</b-badge>
      </b-col>
      <b-col cols="2">
        <b-badge variant="success">slider 1</b-badge>
      </b-col>
      <b-col cols="2">
        <b-badge variant="warning">slider 2</b-badge>
      </b-col>
    </b-row>
    <hr class="my-1">
    <template v-for="(tab, tabIndex) in codeTabs">
      <b-row :key="`tab_${tabIndex}`">
        <b-col cols="12">
          <b-input-group size="sm" class="mb-2">
            <template #prepend>
              <b-input-group-text class="bg-primary">
                <b-badge variant="primary">Tab label:</b-badge>
              </b-input-group-text>
            </template>

            <b-form-input aria-label="Tab label" placeholder="Enter tab label" v-model="tab.label"></b-form-input>
            <template #append>
              <b-dropdown text="Actions" variant="info" size="sm" right>
                <b-dropdown-item @click="addTab(tabIndex + 1)">Add another tab</b-dropdown-item>
                <b-dropdown-item @click="addSlider1Item({tabIndex, slider1Index: 0})">Add slider 1 item
                </b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click=moveTabUp(tabIndex)
                                 :disabled="tabIndex === 0">Move Up
                </b-dropdown-item>
                <b-dropdown-item @click=moveTabDown(tabIndex)
                                 :disabled="tabIndex === codeTabs.length - 1">Move Down
                </b-dropdown-item>
                <b-dropdown-item @click="removeTab(tabIndex)"
                                 :disabled="tabIndex === 0">Remove
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-input-group>
        </b-col>

      </b-row>
      <template v-if="tab.sliders.items.length">
        <b-row :key="`tab_${tabIndex}_label`">
          <b-col cols="2"></b-col>
          <b-col cols="6">
            <b-input-group size="sm" class="mb-2 border border-success">
              <template #prepend>
                <b-input-group-text class="bg-success">
                  <b-badge variant="success">SLIDER LABEL</b-badge>
                </b-input-group-text>
              </template>
              <b-form-input placeholder="Enter slider label"
                            v-model="tab.sliders.label"></b-form-input>
            </b-input-group>
          </b-col>
        </b-row>
      </template>
      <template v-for="(slider1Item, slider1Index) in tab.sliders.items">
        <b-row :key="`slider1_${tabIndex}_${slider1Index}`">
          <b-col cols="2"></b-col>
          <b-col cols="10">
            <b-input-group size="sm" class="mb-2">
              <template #prepend>
                <b-input-group-text class="bg-success">
                  <b-badge variant="success">1st slider item name:</b-badge>
                </b-input-group-text>
              </template>
              <b-form-input aria-label="slider 1 item name"
                            placeholder="Enter item name"
                            v-model="slider1Item.name"></b-form-input>
              <b-form-input aria-label="slider 1 item value"
                            placeholder="Enter daily ration"
                            v-if="typeof slider1Item.value !== 'object'"
                            v-model.number="slider1Item.value"
                            type="number"
                            v-b-tooltip.hover title="Enter daily ration or add item in next slider"></b-form-input>
              <template #append>
                <b-dropdown text="Actions" variant="info" size="sm" right>
                  <b-dropdown-item @click="openGenerateModal({tabIndex, slider1Index: slider1Index + 1})">
                    Generate items
                  </b-dropdown-item>
                  <b-dropdown-item @click="addSlider1Item({tabIndex, slider1Index: slider1Index + 1})">
                    Add item to 1st slider
                  </b-dropdown-item>
                  <b-dropdown-item @click="addSlider2Item({tabIndex, slider1Index, slider2Index: 0})">
                    Add item to 2nd slider
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="moveSlider1Up({tabIndex, slider1Index})"
                                   :disabled="slider1Index === 0">Move Up
                  </b-dropdown-item>
                  <b-dropdown-item
                      @click="moveSlider1Down({tabIndex, slider1Index})"
                      :disabled="slider1Index === tab.sliders.items.length - 1">Move Down
                  </b-dropdown-item>
                  <b-dropdown-item @click="removeSlider1Item({tabIndex, slider1Index})">Remove
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-input-group>
          </b-col>
        </b-row>
        <template v-if="typeof slider1Item.value === 'object'">
          <template v-if="tab.sliders.items.length">
            <b-row :key="`${tabIndex}_${slider1Index}label`">
              <b-col cols="4"></b-col>
              <b-col cols="6">
                <b-input-group size="sm" class="mb-2 border border-warning">
                  <template #prepend>
                    <b-input-group-text class="bg-warning">
                      <b-badge variant="warning">SLIDER LABEL</b-badge>
                    </b-input-group-text>
                  </template>
                  <b-form-input placeholder="Enter slider label"
                                v-model="slider1Item.value.label"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
          </template>
          <template v-for="(slider2Item, slider2Index) in slider1Item.value.items">
            <b-row :key="`slider2_${tabIndex}_${slider1Index}_${slider2Index}`">
              <b-col cols="4"></b-col>
              <b-col cols="8">
                <b-input-group size="sm" class="mb-2">
                  <template #prepend>
                    <b-input-group-text class="bg-warning">
                      <b-badge variant="warning">2nd slider item name:</b-badge>
                    </b-input-group-text>
                  </template>
                  <b-form-input aria-label="slider 1 item name"
                                placeholder="Enter item name"
                                v-model="slider2Item.name"></b-form-input>
                  <b-form-input aria-label="slider 1 item value"
                                placeholder="Enter daily ration"
                                v-model.number="slider2Item.value"
                                type="number"
                                v-b-tooltip.hover title="Enter daily ration"></b-form-input>
                  <template #append>
                    <b-dropdown text="Actions" variant="info" size="sm" right>
                      <b-dropdown-item
                          @click="openGenerateModal({tabIndex, slider1Index, slider2Index: slider2Index + 1})">
                        Generate items
                      </b-dropdown-item>
                      <b-dropdown-item
                          @click="addSlider2Item({tabIndex, slider1Index, slider2Index: slider2Index + 1})">
                        Add item to 2nd slider
                      </b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="moveSlider2Up({tabIndex, slider1Index, slider2Index})"
                                       :disabled="slider2Index === 0">Move Up
                      </b-dropdown-item>
                      <b-dropdown-item @click="moveSlider2Down({tabIndex, slider1Index, slider2Index})"
                                       :disabled="slider2Index === slider1Item.value.items.length - 1">Move Down
                      </b-dropdown-item>
                      <b-dropdown-item @click="removeSlider2Item({tabIndex, slider1Index, slider2Index})">Remove
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-input-group>
              </b-col>
            </b-row>
          </template>
        </template>
      </template>
    </template>
    <CalculatorConfigurationSlidersModal :name="this.generateModalName" @generateItems="generateItems"/>
  </b-container>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import CalculatorConfigurationSlidersModal from "./CalculatorConfigurationSlidersModal";
import deepmerge from "deepmerge";

export default {
  name: "CalculatorConfigurationSliders",
  components: {
    CalculatorConfigurationSlidersModal
  },
  data() {
    return {
      generateModalName: 'generate-modal',
      generateModalConfig: null
    };
  },
  computed: {
    ...mapState({
      codeTabs: state => state.codeData.tabs
    })
  },
  methods: {
    ...mapActions('codeData', [
      'addTab',
      'addSlider1Item',
      'addSlider2Item'
    ]),
    removeTab(value) {
      this.$store.commit('codeData/removeTab', value);
      this.showRemoveMessage();
    },
    removeSlider1Item(value) {
      this.$store.commit('codeData/removeSlider1Item', value);
      this.showRemoveMessage();
    },
    removeSlider2Item(value) {
      this.$store.dispatch('codeData/removeSlider2Item', value);
      this.showRemoveMessage();
    },
    showRemoveMessage() {
      this.showMessage('Element removed successfully', 'Remove element', 'success');
    },
    moveTabUp(index) {
      this.$store.commit('codeData/moveTab', {from: index, to: index - 1});
      this.showMoveMessage();
    },
    moveTabDown(index) {
      this.$store.commit('codeData/moveTab', {from: index, to: index + 1});
      this.showMoveMessage();
    },
    moveSlider1Up({tabIndex, slider1Index}) {
      this.$store.commit('codeData/moveSlider1Item', {tabIndex, from: slider1Index, to: slider1Index - 1});
      this.showMoveMessage();
    },
    moveSlider1Down({tabIndex, slider1Index}) {
      this.$store.commit('codeData/moveSlider1Item', {tabIndex, from: slider1Index, to: slider1Index + 1});
      this.showMoveMessage();
    },
    moveSlider2Up({tabIndex, slider1Index, slider2Index}) {
      this.$store.commit('codeData/moveSlider2Item', {
        tabIndex,
        slider1Index,
        from: slider2Index,
        to: slider2Index - 1
      });
      this.showMoveMessage();
    },
    moveSlider2Down({tabIndex, slider1Index, slider2Index}) {
      this.$store.commit('codeData/moveSlider2Item', {
        tabIndex,
        slider1Index,
        from: slider2Index,
        to: slider2Index + 1
      });
      this.showMoveMessage();
    },
    showMoveMessage() {
      this.showMessage('Element moved successfully', 'Move element', 'success');
    },
    showMessage(message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      })
    },
    openGenerateModal(config) {
      this.generateModalConfig = {
        ...config
      };
      this.showGenerateModal();
    },
    generateItems(items) {
      const {tabIndex, slider1Index, slider2Index} = this.generateModalConfig;

      if (this.generateModalConfig.slider2Index) {
        items.forEach((item, index) => {
          item = deepmerge({}, item);
          this.addSlider2Item({tabIndex, slider1Index, slider2Index: slider2Index + index, item});
        })
        const currItem = this.codeTabs[tabIndex].sliders.items[slider1Index].value.items[slider2Index - 1];

        if (currItem.name === '' && currItem.value === '') {
          this.$store.commit('codeData/removeSlider2Item', {tabIndex, slider1Index, slider2Index: slider2Index - 1});
        }
      } else {
        items.forEach((item, index) => {
          item = deepmerge({}, item);
          this.addSlider1Item({tabIndex, slider1Index: slider1Index + index, item});
        });
        const currItem = this.codeTabs[tabIndex].sliders.items[slider1Index - 1];

        if (currItem.name === '' && currItem.value === '') {
          this.$store.commit('codeData/removeSlider1Item', {tabIndex, slider1Index: slider1Index - 1});
        }
      }
    },
    showGenerateModal() {
      this.$modal.show(this.generateModalName);
    }
  }
}
</script>

<style lang="scss" scoped>
.border {
  border-width: 2px;
  border-radius: 4px;
}
</style>
