<template>
  <div id="app">
    <Nav/>
    <CalculatorView/>
    <CalculatorConfiguration/>
    <CalculatorCode/>
    <CalculatorEdit/>
  </div>
</template>

<script>
import Nav from './components/Nav.vue';
import CalculatorCode from './components/CalculatorCode.vue';
import CalculatorConfiguration from './components/CalculatorConfiguration.vue';
import CalculatorView from './components/CalculatorView.vue';
import CalculatorEdit from './components/CalculatorEdit.vue';

export default {
  name: 'App',
  components: {
    Nav,
    CalculatorView,
    CalculatorConfiguration,
    CalculatorCode,
    CalculatorEdit
  }
}
</script>

<style lang="scss">
@import './assets/scss/index';
</style>
