import template from "./template.html";

export default {
    name: "SCalculatorAppNoSlider",
    template,
    props: {
        config: {
            type: Object,
            require: true
        }
    }
}
