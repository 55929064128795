export default {
    namespaced: true,
    state: () => ({
        item: {
            "name": "",
            "value": ""
        },
        slider: {
            "label": "",
            "items": [
                {
                    "name": "",
                    "value": ""
                }
            ]
        },
        tab: {
            "label": "",
            "sliders": {
                "label": "",
                "items": [
                    {
                        "name": "",
                        "value": ""
                    }
                ]
            }
        }
    })
}
