<template>
  <modal class="pt-2" :name="name" width="80%" height="auto" scrollable :shiftY="0"
         @before-close="setDefaultData">
    <div class="p-4">
      <b-card title="Items generator" class="mb-4">
        <b-row>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group label="Label value from">
              <b-form-input type="number"
                            v-model.number="labelValueFrom"
                            size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group label="Label value to">
              <b-form-input type="number"
                            v-model.number="labelValueTo"
                            size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group label="Label text after value">
              <b-form-input type="text"
                            v-model="labelTextAfterValue"
                            size="sm"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" sm="6" lg="4" class="mb-2">
            <b-form-group label="Count Method Option">
              <b-form-select v-model="countMethodValue" :options="countMethodOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <template v-if="countMethodValue === multiplyRangeLabelName">
            <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
              <b-form-group label="Multiply range from">
                <b-form-input type="number"
                              v-model.number="rangeFrom"
                              size="sm"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
              <b-form-group label="Multiply range to">
                <b-form-input type="number"
                              v-model.number="rangeTo"
                              size="sm"></b-form-input>
              </b-form-group>
            </b-col>
          </template>
          <template v-else>
            <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
              <b-form-group label="Base value">
                <b-form-input type="number"
                              v-model.number="baseValue"
                              size="sm"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2" v-if="countMethodValue === addMethodName">
              <b-form-group label="Increment value">
                <b-form-input type="number"
                              v-model.number="incrementValue"
                              size="sm"></b-form-input>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
        <hr>
        <b-button @click="hideModal"
                  class="m-1"
                  size="sm"
                  variant="danger">
          Cancel
        </b-button>
        <b-button class="m-1"
                  size="sm"
                  @click="onGenerate"
                  variant="success"
                  :disabled="!items.length">
          Generate items
        </b-button>
      </b-card>
      <b-card title="Items view">
        <template v-for="(item, index) in items">
          <b-row :key="index">
            <b-col cols="12">
              <b-input-group size="sm" class="mb-2">
                <template #prepend>
                  <b-input-group-text class="bg-secondary">
                    <b-badge variant="secondary">slider item name:</b-badge>
                  </b-input-group-text>
                </template>
                <b-form-input aria-label="slider item name"
                              placeholder="Enter item name"
                              disabled
                              :value="item.name"></b-form-input>
                <b-form-input aria-label="slider 1 item value"
                              placeholder="Enter daily ration"
                              :value="item.value"
                              type="number"
                              disabled
                ></b-form-input>
              </b-input-group>
            </b-col>
          </b-row>
        </template>
      </b-card>
    </div>
  </modal>
</template>

<script>
export default {
  name: "CalculatorConfigurationSlidersModal",
  props: {
    name: {
      type: String,
      required: true
    }
  },
  data() {
    const multiplyMethodName = 'multiplyLabelValue',
        addMethodName = 'addValue',
        multiplyRangeLabelName = 'multiplyRangeLabelValue';

    return {
      labelValueFrom: 0,
      labelValueTo: 0,
      labelTextAfterValue: '',
      baseValue: 0,
      incrementValue: 0,
      rangeFrom: 20,
      rangeTo: 10,
      multiplyMethodName,
      addMethodName,
      multiplyRangeLabelName,
      countMethodValue: '',
      countMethodOptions: [
        {
          value: multiplyMethodName,
          text: 'Multiply \'base value\' and \'label value\'',
          handlerName: 'multiplyLabelHandler'
        },
        {
          value: multiplyRangeLabelName,
          text: 'Multiply range and \'label value\'',
          handlerName: 'multiplyRangeLabelHandler'
        },
        {
          value: addMethodName,
          text: 'Add \'increment value\' to each item',
          handlerName: 'addMethodHandler'
        }
      ]
    }
  },
  computed: {
    itemsNumber() {
      return this.labelValueTo > this.labelValueFrom ? this.labelValueTo - this.labelValueFrom + 1 : 0;
    },
    items() {
      if (this.itemsNumber > 0) {
        return Array(this.itemsNumber).fill(0).map((x, y) => {
          const currIndex = x + y;

          return {
            name: `${currIndex + this.labelValueFrom}${this.labelTextAfterValue}`,
            value: this.getItemValue(currIndex)
          };
        });
      }
      return [];
    },
    countMethodOption() {
      return this.countMethodOptions.find(item => item.value === this.countMethodValue);
    }
  },
  methods: {
    getItemValue(index) {
      const {handlerName} = this.countMethodOption;

      return handlerName ? this[handlerName](index) : 0;
    },
    multiplyLabelHandler(index) {
      return (index + this.labelValueFrom) * this.baseValue;
    },
    addMethodHandler(index) {
      return this.baseValue + (index * this.incrementValue);
    },
    multiplyRangeLabelHandler(index) {
      const stepCount = this.labelValueFrom - this.labelValueTo,
          rangeCount = this.rangeFrom - this.rangeTo,
          incrementValue = rangeCount / stepCount,
          stepValue = index + this.labelValueFrom,
          rangeValue = this.rangeFrom + (incrementValue * index);

      return Math.round(stepValue * rangeValue);
    },
    setDefaultData() {
      this.labelValueFrom = 1;
      this.labelValueTo = 2;
      this.labelTextAfterValue = ' kg';
      this.baseValue = 100;
      this.incrementValue = 10;
      this.countMethodValue = 'multiplyLabelValue';
    },
    onGenerate() {
      this.$emit('generateItems', this.items);
      this.setDefaultData();
      this.hideModal();
    },
    hideModal() {
      this.$modal.hide(this.name);
    }
  },
  mounted() {
    this.setDefaultData();
  }
}
</script>
<style lang="scss">
.vm {
  &--container {
    &.scrollable {
      background: rgba(0, 0, 0, .2);
    }
  }

  &--overlay {
    background: none !important;
  }
}
</style>