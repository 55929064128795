import template from "./App.html";
import SCalculatorLayout from "./components/SCalculatorLayout";
import SCalculatorContent from "./components/SCalculatorContent";
import SCalculatorApp from "./components/SCalculatorApp";
import SCalculatorFile from "./components/SCalculatorFile";
import SCalculatorImg from "./components/SCalculatorImg";
import SCalculatorAppNoSlider from "./components/SCalculatorAppNoSlider";

export default {
    name: "App",
    components: {
        SCalculatorLayout,
        SCalculatorContent,
        SCalculatorApp,
        SCalculatorFile,
        SCalculatorImg,
        SCalculatorAppNoSlider
    },
    template,
    props: {
        appData: {
            type: Object,
            default() {
                return {};
            }
        },
        translations: {
            type: Object,
            default() {
                return {};
            }
        },
        productPrice: {
            type: Number,
            default: 0
        },
        fullProductPrice: {
            type: String,
            default: ''
        },
        sliderDotIcon: {
            type: String,
            require: true
        },
        priceFormat: {
            type: Object,
            require: true
        }
    }
}
