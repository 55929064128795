// change path
import CurrencyFormatter from '../../StrixCurrencyFormatter/src/Resources/app/storefront/src/utility/currencyFormatter.util'

function getFormatter () {
    const { localeData, strixCurrencyFormatter } = window;

    if (!strixCurrencyFormatter) {
        return new CurrencyFormatter(localeData || {});
    }
    return strixCurrencyFormatter;
}

const formatter = getFormatter();

export default formatter;
