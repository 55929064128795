<template>
  <b-container>
    <b-card title="Configuration" class="mb-2">
      <b-row>
        <CalculatorConfigurationConfig/>
      </b-row>
    </b-card>
    <template v-if="!codeData.config.showSingleDailyRationWithoutCalculation">
      <b-card v-if="codeData.config.isNoSlidersView" title="No sliders view configuration" class="mb-2">
        <b-row>
          <CalculatorConfigurationNoSlidersView  />
        </b-row>
      </b-card>
      <b-card v-else title="Tabs and Sliders Configuration" class="mb-2">
        <b-row>
          <CalculatorConfigurationSlidersView />
        </b-row>
      </b-card>
    </template>
  </b-container>
</template>

<script>
import {mapState} from 'vuex';
import CalculatorConfigurationConfig from "./CalculatorConfigurationConfig";
import CalculatorConfigurationSlidersView from "./CalculatorConfigurationSlidersView";
import CalculatorConfigurationNoSlidersView from "./CalculatorConfigurationNoSlidersView";

export default {
  name: "CalculatorConfiguration",
  components: {
    CalculatorConfigurationConfig,
    CalculatorConfigurationSlidersView,
    CalculatorConfigurationNoSlidersView
  },
  computed: {
    ...mapState({
      defaultData: state => state.defaultData,
      codeData: state => state.codeData
    })
  }
}
</script>
