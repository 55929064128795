<template>
  <b-container class="calculator-view">
    <b-card title="Live view price/custom-fields configuration" class="mb-4 mt-4">
      <b-row>
        <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
          <b-form-group
              description="Shopware value is based on product price"
              label="Product Price Value"
          >
            <b-form-input type="number"
                          v-model.number="productPrice"
                          size="sm"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2 d-flex align-items-center">
          <b-button v-b-toggle.collapse-configuration
                    class="m-1"
                    size="sm"
                    variant="warning">Toggle configuration
          </b-button>
        </b-col>
      </b-row>

      <b-collapse id="collapse-configuration">
        <b-row>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group
                description="Please fill in Custom Field BTF Calculator - Header"
                label="Slider Header"
            >
              <b-form-input type="text"
                            v-model="title"
                            size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group
                description="Please fill in Custom Field BTF Calculator - Description"
                label="Slider Description"
            >
              <b-form-input type="text"
                            v-model="description"
                            size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group
                description="Please choose media image in Custom Field BTF Calculator - image"
                label="Slider Image Url"
            >
              <b-form-input type="text"
                            v-model="image"
                            size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group
                description="Shopware Slider Dot Icon is based on product properties"
                label="Slider dot icon"
            >
              <b-form-select v-model="sliderDotIcon" :options="sliderDotIconOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group
                description="Please choose file in Custom Field BTF Calculator - Feeding table PDF"
                label="Feeding table PDF Url"
            >
              <b-form-input type="text"
                            v-model="fileUrl"
                            size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group
                description="Please choose image in Custom Field BTF Calculator - Icon 1"
                label="Non Slider View Icon 1 Url"
            >
              <b-form-input type="text"
                            v-model="icon1"
                            size="sm"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
            <b-form-group
                description="Please choose image in Custom Field BTF Calculator - Icon 2"
                label="Non Slider View Icon 2 Url"
            >
              <b-form-input type="text"
                            v-model="icon2"
                            size="sm"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-collapse>

    </b-card>
    <SCalculatorViewComponents
        v-if="title && description"
        class="calculator-view__components mb-4"
        :appData="appData"
        :translations="translations"
        :priceFormat="priceFormat"/>
  </b-container>
</template>

<script>
import {mapState} from 'vuex';
import deepmerge from "deepmerge";
import SCalculatorViewComponents from './SCalculatorViewComponents/App';

export default {
  name: 'CalculatorView',
  components: {
    SCalculatorViewComponents
  },
  data() {
    return {
      productPrice: 0,
      title: '',
      description: '',
      image: '',
      sliderDotIcon: '',
      sliderDotIconOptions: [
        {value: require('@/assets/imgs/slider-icon-default.png'), text: 'Default'},
        {value: require('@/assets/imgs/slider-icon-dog.png'), text: 'Dog'},
        {value: require('@/assets/imgs/slider-icon-cat.png'), text: 'Cat'},
      ],
      fileUrl: '',
      icon1: '',
      icon2: '',
      configurationDefaultDataKeys: [
        'productPrice',
        'title',
        'description',
        'image',
        'sliderDotIcon',
        'fileUrl',
        'icon1',
        'icon2'
      ],
      priceFormat: {
        precision: 2, decimalSymbol: ',',
        pattern: '%s zł',
        groupLength: 3, groupSymbol: ' '
      },
      activeTabIndex: 0,
      translations: {
        fileDescription: 'download pdf file with feeding table'
      }
    }
  },
  computed: {
    ...mapState({
      defaultData: state => state.defaultData,
      codeData: state => state.codeData
    }),
    appData() {
      const appData = {
        config: deepmerge(this.defaultData.config, this.codeData.config),
        tabs: deepmerge([], this.codeData.tabs)
      };

      this.configurationDefaultDataKeys.forEach(key => {
        appData.config[key] = this[key];
      })

      return this.setDefaultData(appData);
    }
  },
  methods: {
    setInitData() {
      this.configurationDefaultDataKeys.forEach(key => {
        this[key] = this.defaultData.config[key];
      })
    },
    setDefaultData(appData) {
      const defaultKeys = [
        'calculatorWeightUnit',
        'secondFeedOptionHeader',
        'secondFeedOptionLabel',
        'firstFeedOptionHeader',
        'firstFeedOptionLabel',
        'hideSecondFeedOption',
        'showSecondFeedOptionIcon',
        'costLabel',
        'iconLabel1',
        'iconLabel2',
        'rationLabel'
      ];

      const appDataCopy = deepmerge({}, appData)

      defaultKeys.forEach(key => {
        if (appDataCopy.config[key] === '') {
          appDataCopy.config[key] = this.defaultData.config[key]
        }
      })

      return appDataCopy;
    }
  },
  created() {
    this.setInitData();
  }
}
</script>

<style lang="scss">
.calculator-view {
  &__components {
    border: 1px solid rgba(0, 0, 0, 0.125)

  }
}
</style>
