<template>
  <b-container>
    <b-card title="Edit JSON" class="mb-4 pb-4">
      <b-row>
        <b-col>
          <b-input-group class="mt-3">
            <template #append>
              <b-button variant="danger" @click="saveJson">Save JSON</b-button>
            </template>
            <b-form-input v-model="editJsonInput"></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>

<script>
export default {
  name: "CalculatorEdit",
  data() {
    return {
      editJsonInput: ''
    }
  },
  methods: {
    saveJson() {
      if (this.isJsonValidJSON(this.editJsonInput)) {
        this.$store.dispatch('codeData/saveJsonData', JSON.parse(this.editJsonInput))
        this.clearInput();
        this.successMessage();
      } else {
        this.$bvToast.toast('Invalid JSON format', {
          title: 'Edit JSON',
          variant: 'danger',
          solid: true
        })
      }
    },
    successMessage() {
      this.$bvToast.toast('JSON saved successfully', {
        title: 'Edit JSON',
        variant: 'success',
        solid: true
      })
    },
    isJsonValidJSON(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    clearInput() {
      this.editJsonInput = '';
    }
  }
}
</script>
