import template from "./template.html";

export default {
    name: "SCalculatorAppTabsItem",
    template,
    props: {
        label: {
            type: String,
            default: ''
        },
        active: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        onTabClick() {
            this.$emit('onTabClick');
        }
    }
}
