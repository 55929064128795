import template from "./template.html";

export default {
    name: "SCalculatorAppTotal",
    template,
    props: {
        costLabel: {
            type: String,
            default: ''
        },
        rationLabel: {
            type: String,
            default: ''
        },
        costValue: {
            type: String,
            default: ''
        },
        rationValue: {
            type: String,
            default: ''
        },
        showSingleDailyRationWithoutCalculation: {
            type: Boolean,
            default: false
        }
    }
}
