import Vue from 'vue';
import Vuex from 'vuex';
import codeData from './modules/codeData';
import defaultData from './modules/defaultData';
import templatesData from './modules/templatesData';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        codeData,
        defaultData,
        templatesData
    }
})
