<template>
  <b-container>
    <b-row>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group
            description="Weight must be the same unit as daily ration"
            label="Product Weight"
            label-for="input-1"
            invalid-feedback="Please enter value"
            :state="productWeightValueState"
        >
          <b-form-input type="number"
                        v-model.number="productWeightValue"
                        size="sm"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group
            description="Unit is added on total immediately after value (e.g. ' g' = 70 g)"
            label="Calculator Weight Unit Format"
        >
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Change default value" switch class="mr-n2 mb-n1"
                               @change="switchChange(changeCalculatorWeightUnit, 'calculatorWeightUnit')"
                               v-model="changeCalculatorWeightUnit">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input :placeholder="defaultDataConfig.calculatorWeightUnit"
                          v-model="calculatorWeightUnit"
                          :disabled="!changeCalculatorWeightUnit"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="Total Cost Label">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Change default value" switch class="mr-n2 mb-n1"
                               @change="switchChange(changeCostLabel, 'costLabel')"
                               v-model="changeCostLabel">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input :placeholder="defaultDataConfig.costLabel"
                          :disabled="!changeCostLabel"
                          v-model="costLabel"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="Total Ration Label">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Change default value" switch class="mr-n2 mb-n1"
                               @change="switchChange(changeRationLabel, 'rationLabel')"
                               v-model="changeRationLabel">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input :placeholder="defaultDataConfig.rationLabel"
                          v-model="rationLabel"
                          :disabled="!changeRationLabel"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group
            label="Show Calculator Tabs">
          <b-input-group
              size="sm">
            <b-form-checkbox v-model="showTabs" name="check-button" switch>
            </b-form-checkbox>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group
            label="Show only single daily ration value"
            description="Only this value will be visible on totals. Please add unit."
            invalid-feedback="Please enter value"
            :state="!(showSingleDailyRationWithoutCalculation && singleDailyRationWithoutCalculationValue === '')"
        >
          <b-input-group
              size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Show only single daily ration value. Daily price calculation will be hidden"
                               switch class="mr-n2 mb-n1"
                               @change="switchChange(showSingleDailyRationWithoutCalculation, 'singleDailyRationWithoutCalculationValue')"
                               v-model="showSingleDailyRationWithoutCalculation">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input
                :placeholder="defaultDataConfig.singleDailyRationWithoutCalculationValue"
                v-model="singleDailyRationWithoutCalculationValue"
                :disabled="!showSingleDailyRationWithoutCalculation"
                trim></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group
            label="Show view without tabs and sliders"
        >
          <b-input-group
              size="sm">
            <b-form-checkbox v-model="isNoSlidersView" name="check-button" switch>
            </b-form-checkbox>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>

</template>

<script>
import {mapState, mapMutations} from 'vuex';
import setComputedCodeDataConfig from '../helpers/set-computed-code-data-config';

export default {
  name: "CalculatorConfigurationConfig",
  data() {
    return {
      changeCalculatorWeightUnit: false,
      changeCostLabel: false,
      changeRationLabel: false
    }
  },
  computed: {
    ...mapState({
      defaultDataConfig: state => state.defaultData.config,
      codeDataConfig: state => state.codeData.config
    }),
    productWeightValue: setComputedCodeDataConfig('productWeightValue'),
    calculatorWeightUnit: setComputedCodeDataConfig('calculatorWeightUnit'),
    costLabel: setComputedCodeDataConfig('costLabel'),
    rationLabel: setComputedCodeDataConfig('rationLabel'),
    showTabs: setComputedCodeDataConfig('showTabs'),
    showSingleDailyRationWithoutCalculation: setComputedCodeDataConfig('showSingleDailyRationWithoutCalculation'),
    singleDailyRationWithoutCalculationValue: setComputedCodeDataConfig('singleDailyRationWithoutCalculationValue'),
    isNoSlidersView: setComputedCodeDataConfig('isNoSlidersView'),

    productWeightValueState() {
      if (this.showSingleDailyRationWithoutCalculation || this.isNoSlidersView) {
        return true;
      }
      return this.productWeightValue > 0;
    }
  },
  methods: {
    ...mapMutations('codeData', [
      'setConfig'
    ]),
    switchChange(checkboxValue, key, clearValue = '') {
      if (!checkboxValue) {
        this.setConfig({key, value: clearValue});
      }
    }
  }
}
</script>
