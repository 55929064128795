import template from "./template.html";

export default {
    name: "SCalculatorFile",
    template,
    props: {
        url: {
            type: String,
            default: ''
        },
        translations: {
            type: Object,
            require: true
        }
    }
}
