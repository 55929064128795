export default function createComputed(name) {
    return {
        get() {
            return this.$store.state.codeData.config[name];
        },
        set(value) {
            this.setConfig({key: name, value});
        }
    }
}
