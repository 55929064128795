import template from "./template.html";
import SCalculatorAppTabs from './SCalculatorAppTabs';
import SCalculatorAppSlidersWrapper from "./SCalculatorAppSlidersWrapper";
import SCalculatorAppTotal from "./SCalculatorAppTotal";
import Formatter from '../../helpers/formatter';

export default {
    name: "SCalculatorApp",
    template,
    components: {
        SCalculatorAppTabs,
        SCalculatorAppSlidersWrapper,
        SCalculatorAppTotal
    },
    props: {
        appData: {
            type: Object,
            default() {
                return {};
            }
        },
        productPrice: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            activeTabIndex: 0,
            firstSliderIndex: 0,
            secondSliderIndex: 0
        }
    },
    computed: {
        tabs() {
            return this.appData.tabs.map(tab => tab.label);
        },
        activeTab() {
            return this.appData.tabs[this.activeTabIndex];
        },
        firstSlider() {
            return this.activeTab.sliders.items[this.firstSliderIndex];
        },
        secondSlider() {
            const items = this.firstSlider.value.items;

            return items ? items[this.secondSliderIndex] : null;
        },
        rationValue() {
            if (this.appData.config.showSingleDailyRationWithoutCalculation) {
                return this.appData.config.singleDailyRationWithoutCalculationValue;
            }
            return this.secondSlider ? this.secondSlider.value : this.firstSlider.value;
        },
        rationFormatedValue() {
            if (this.appData.config.showSingleDailyRationWithoutCalculation) {
                return this.rationValue;
            }
            return this.rationValue ? `${this.rationValue}${this.appData.config.calculatorWeightUnit}` : '';
        },
        dailyCostValue() {
            if (!this.appData.config.showSingleDailyRationWithoutCalculation) {
                const productWeightValue = parseFloat(this.appData.config.productWeightValue);

                if (this.rationValue && productWeightValue && productWeightValue > 0) {
                    const dailyCostValue = (parseFloat(this.rationValue) * this.appData.config.productPrice) / productWeightValue;

                    return Formatter.formatPrice(dailyCostValue);
                }
            }
            return '';
        },
        showTabs() {
            return !this.appData.config.showSingleDailyRationWithoutCalculation &&
                this.appData.config.showTabs &&
                this.appData.tabs[0].label;
        }
    },
    methods: {
        onTabClick(index) {
            this.activeTabIndex = index;
            this.validateSliders({tabIndex: index});
        },
        onFirstSliderChange(index) {
            this.firstSliderIndex = index;
            this.validateSliders({firstSliderIndex: index});
        },
        onSecondSliderChange(index) {
            this.secondSliderIndex = index;
        },
        validateSliders() {
            const firstSliderMax = this.appData.tabs[this.activeTabIndex].sliders.items.length - 1;

            if (this.firstSliderIndex > firstSliderMax) {
                this.firstSliderIndex = firstSliderMax;
            }

            const currSliderPosition = this.appData.tabs[this.activeTabIndex].sliders.items[this.firstSliderIndex];
            const isSecondSlider = typeof currSliderPosition.value === 'object';

            if (isSecondSlider) {
                const secondSliderMax = currSliderPosition.value.items.length - 1;

                if (this.secondSliderIndex > secondSliderMax) {
                    this.secondSliderIndex = secondSliderMax;
                }
            }
        }
    }
}
