export default {
    namespaced: true,
    state: () => ({
        "config": {
            "image": require('@/assets/imgs/default_calculator_img.png'),
            "title": "Calculate your pet’s daily ration",
            "description": "To ease the transition from mother’s milk to solid food, at around 4 weeks of age you can moisten Husse puppy food kibbles with some lukewarm water. At first add a lot of water (to obtain a porridge), and then gradually reduce the amount of water as the puppy matures. Around the age of 8 weeks your puppy should be eating solid food. Puppies under 6 months of age should be fed 3-4 times daily; after 6 months, you may start feeding twice a day. Make it a habit to give the puppy some quiet time after the meal. For daily ration see feeding table. The feeding table is merely a guide, the daily amount has to be adjusted according to the puppy’s individual requirements. A bowl of fresh water should always be available to your dog.",
            "productWeightValue": 5000,
            "calculatorWeightUnit": " g",
            "costLabel": "Daily cost:",
            "rationLabel": "Daily ration:",
            "showTabs": true,
            "showSingleDailyRationWithoutCalculation": false,
            "singleDailyRationWithoutCalculationValue": "50 - 100 g",
            "isNoSlidersView": false,
            "icon1": require('@/assets/imgs/no-slider-view-icon1.png'),
            "icon2": require('@/assets/imgs/no-slider-view-icon2.png'),
            "iconLabel1": "Water",
            "iconLabel2": "Hay",
            "firstFeedOptionHeader": "Feeding advice as complimentary feed:",
            "firstFeedOptionLabel": "Daily ration:",
            "firstFeedOptionValue": "500-1000gr / 100kg of body weight",
            "secondFeedOptionHeader": "Feeding advice as complete feed:",
            "secondFeedOptionLabel": "Daily ration:",
            "secondFeedOptionValue": "500-1000gr / 100kg of body weight",
            "hideSecondFeedOption": false,
            "showSecondFeedOptionIcon": false,
            "fileUrl": "#",
            "productPrice": 129,
            "sliderDotIcon": require('@/assets/imgs/slider-icon-default.png')
        },
        "tabs": [
            {
                "label": "",
                "sliders": {
                    "label": "",
                    "items": [
                        {
                            "name": "",
                            "value": ""
                        }
                    ]
                }
            }
        ]
    })
}