<template>
  <b-container>
    <b-row>
      <b-col>
        <b-jumbotron>
          <b-button @click="copyCode" variant="danger">Copy code</b-button>
          <hr class="my-4">
          <div class="s-calculator-code">{{ code }}</div>
        </b-jumbotron>
      </b-col>
    </b-row>
    <CopyTextarea v-if="initCopy" :text="stringCode" v-model="initCopy"/>
  </b-container>
</template>

<script>
import CopyTextarea from "./CopyTextarea";
import {mapState} from 'vuex'

export default {
  name: "CalculatorCode",
  components: {
    CopyTextarea
  },
  data() {
    return {
      initCopy: false
    }
  },
  computed: {
    ...mapState({
      codeData: state => state.codeData
    }),
    stringCode() {
      return JSON.stringify(this.codeData);
    },
    code() {
      return JSON.parse(this.stringCode);
    }
  },
  methods: {
    copyCode() {
      this.initCopy = true;
      this.makeToast();
    },
    makeToast() {
      this.$bvToast.toast('Code copied successfully', {
        title: 'Copy code',
        variant: 'success',
        solid: true
      })
    }
  }
}
</script>

<style lang="scss">
.s-calculator-code {
  white-space: pre;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 14px;
  overflow: auto;
}
</style>
