import template from "./template.html";
//https://nightcatsama.github.io/vue-slider-component/
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
    name: "SCalculatorAppSlider",
    template,
    components: {
        VueSlider
    },
    props: {
        slider: {
            type: Object,
            require: true
        },
        sliderDotIcon: {
            type: String,
            require: true
        },
        sliderIndex: {
            type: Number,
            require: true
        }
    },
    data() {
        return {
            minValue: 0
        }
    },
    computed: {
        maxValue() {
            return this.slider ? this.slider.items.length - 1 : this.sliderIndex;
        },
        valueName() {
            return this.slider && this.slider.items[this.value] ? this.slider.items[this.value].name : '';
        },
        value: {
            get() {
                return this.sliderIndex;
            },
            set(newValue) {
                this.$emit('indexChange', newValue);
            }
        }
    }
}
