export default class CurrencyFormatterUtil {

    useFormattingByLocale = false;

    constructor({
        currentLocale = '',
        currencyShortName = '',
        precision = 2,
        decimalSymbol = '.',
        currencySymbol = '',
        pattern = '%1 %2',
        groupLength = 3,
        groupSymbol = ' '
    }) {
        const isLocaleSupports = this._toLocaleStringSupportsLocales();

        this.currentLocale = currentLocale;
        this.currencyShortName = currencyShortName;
        this.precision = precision;
        this.decimalSymbol = decimalSymbol;
        this.currencySymbol = currencySymbol;
        this.pattern = pattern;
        this.groupLength = groupLength;
        this.groupSymbol = groupSymbol;

        if (isLocaleSupports && currentLocale && currencyShortName) {
            this.useFormattingByLocale = true;
        }
    }

    _toLocaleStringSupportsLocales() {
        try {
            new Date().toLocaleString('i');
        } catch (e) {
            return e.name === 'RangeError';
        }
        return false;
    }

    _formatPriceByLocale(price) {
        return parseFloat(price).toLocaleString(this.currentLocale,
            {
                style: 'currency',
                currency: this.currencyShortName
            }
        );
    }

    static setFormatPrecision(price, precision) {
        return (Math.round(price * 100) / 100).toFixed(precision);
    }

    static setDecimalSymbol(price, decimalSymbol) {
        return price.replace('.', decimalSymbol);
    }

    static setPriceToPattern(price, pattern) {
        return pattern.replace('%1', price);
    }

    static setCurrencySymbolToPrice(symbol, price) {
        return price.replace('%2', symbol);
    }

    static groupPrice(price, groupLength, groupSymbol) {
        const splitSymbol = '.';
        const groupedPrice = price.split(splitSymbol)[0].split('');
        let lengthFlag = groupedPrice.length - groupLength;

        while (lengthFlag > 0) {
            groupedPrice.splice(lengthFlag, 0, groupSymbol);
            lengthFlag -= groupLength;
        }

        return groupedPrice.join('').concat(splitSymbol, price.split(splitSymbol)[1]);
    }

    _formatPriceDefault(price) {
        const {
            setFormatPrecision,
            groupPrice,
            setDecimalSymbol,
            setPriceToPattern,
            setCurrencySymbolToPrice
        } = CurrencyFormatterUtil;
        const {
            precision,
            decimalSymbol,
            pattern,
            groupLength,
            groupSymbol,
            currencySymbol
        } = this;
        let formattedPrice = setFormatPrecision(price, precision);

        formattedPrice = groupPrice(formattedPrice, groupLength, groupSymbol);
        formattedPrice = setDecimalSymbol(formattedPrice, decimalSymbol);
        formattedPrice = setPriceToPattern(formattedPrice, pattern);
        formattedPrice = setCurrencySymbolToPrice(currencySymbol, formattedPrice);

        return formattedPrice;
    }

    formatPrice(price, useDefaultFormatting) {
        if (this.useFormattingByLocale && !useDefaultFormatting) {
            return this._formatPriceByLocale(price);
        }
        return this._formatPriceDefault(price);
    }
}
