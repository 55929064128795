import template from "./template.html";
import SCalculatorAppSlider from "../SCalculatorAppSlider";

export default {
    name: "SCalculatorAppSlidersWrapper",
    template,
    components: {
        SCalculatorAppSlider
    },
    props: {
        sliders: {
            type: Object,
            require: true
        },
        sliderDotIcon: {
            type: String,
            require: true
        },
        firstSliderIndex: {
            type: Number,
            require: true
        },
        secondSliderIndex: {
            type: Number,
            require: true
        }
    },
    computed: {
        showFirstSlider() {
            return this.sliders.items.length > 1;
        },
        firstSlider() {
            return this.sliders;
        },
        showSecondSlider() {
            return typeof this.sliders.items[this.firstSliderIndex].value === 'object';
        },
        secondSlider() {
            if (this.showSecondSlider) {
                return this.sliders.items[this.firstSliderIndex].value;
            }
            return null;
        }
    },
    methods: {
        onFirstSliderChange(index) {
            this.$emit('onFirstSliderChange', index);
        },
        onSecondSliderChange(index) {
            this.$emit('onSecondSliderChange', index);
        }
    }
}
