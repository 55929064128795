import deepmerge from "deepmerge";

export default {
    namespaced: true,
    state: () => ({
        "config": {
            "productWeightValue": 0,
            "calculatorWeightUnit": "",
            "costLabel": "",
            "rationLabel": "",
            "showTabs": true,
            "showSingleDailyRationWithoutCalculation": false,
            "singleDailyRationWithoutCalculationValue": "",
            "isNoSlidersView": false,
            "iconLabel1": "",
            "iconLabel2": "",
            "firstFeedOptionHeader": "",
            "firstFeedOptionLabel": "",
            "firstFeedOptionValue": "",
            "secondFeedOptionHeader": "",
            "secondFeedOptionLabel": "",
            "secondFeedOptionValue": "",
            "hideSecondFeedOption": false,
            "showSecondFeedOptionIcon": false
        },
        "tabs": [
            {
                "label": "",
                "sliders": {
                    "label": "",
                    "items": [
                        {
                            "name": "",
                            "value": ""
                        }
                    ]
                }
            }
        ]
    }),
    mutations: {
        setConfig(state, {key, value}) {
            state.config[key] = value;
        },
        addTab(state, {item, index}) {
            state.tabs.splice(index, 0, item);
        },
        moveTab(state, {from, to}) {
            const item = state.tabs.splice(from, 1)[0];

            state.tabs.splice(to, 0, item);
        },
        removeTab(state, index) {
            state.tabs.splice(index, 1);
        },
        addSlider1(state, {tabIndex, slider1Index, item}) {
            state.tabs[tabIndex].sliders.items.splice(slider1Index, 0, item);
        },
        moveSlider1Item(state, {tabIndex, from, to}) {
            const item = state.tabs[tabIndex].sliders.items.splice(from, 1)[0];

            state.tabs[tabIndex].sliders.items.splice(to, 0, item);
        },
        removeSlider1Item(state, {tabIndex, slider1Index}) {
            state.tabs[tabIndex].sliders.items.splice(slider1Index, 1);
        },
        setSlider2(state, {tabIndex, slider1Index, item}) {
            state.tabs[tabIndex].sliders.items[slider1Index].value = item;
        },
        addSlider2(state, {item, tabIndex, slider1Index, slider2Index}) {
            state.tabs[tabIndex].sliders.items[slider1Index].value.items.splice(slider2Index, 0, item);
        },
        moveSlider2Item(state, {tabIndex, slider1Index, from, to}) {
            const item = state.tabs[tabIndex].sliders.items[slider1Index].value.items.splice(from, 1)[0];

            state.tabs[tabIndex].sliders.items[slider1Index].value.items.splice(to, 0, item);
        },
        removeSlider2Item(state, {tabIndex, slider1Index, slider2Index}) {
            state.tabs[tabIndex].sliders.items[slider1Index].value.items.splice(slider2Index, 1);
        },
        setSlider2ItemValue(state, {tabIndex, slider1Index, value}) {
            state.tabs[tabIndex].sliders.items[slider1Index].value = value;
        },
        saveJsonData(state, {key, value}) {
            state[key] = deepmerge({}, value);
        }
    },
    actions: {
        addTab({commit, rootState}, index) {
            const item = deepmerge({}, rootState.templatesData.tab);

            commit('addTab', {item, index});
        },
        addSlider1Item({commit, rootState}, {tabIndex, slider1Index, item}) {
            if (!item) {
                item = deepmerge({}, rootState.templatesData.item);
            }
            commit('addSlider1', {item, tabIndex, slider1Index});
        },
        addSlider2Item({commit, rootState, state}, {tabIndex, slider1Index, slider2Index, item}) {
            const {value} = state.tabs[tabIndex].sliders.items[slider1Index];

            if (typeof value !== "object") {
                const item = deepmerge({}, rootState.templatesData.slider);

                commit('setSlider2', {item, tabIndex, slider1Index});
            } else {
                if (!item) {
                    item = deepmerge({}, rootState.templatesData.item);
                }

                commit('addSlider2', {item, tabIndex, slider1Index, slider2Index});
            }
        },
        removeSlider2Item({commit, state}, {tabIndex, slider1Index, slider2Index}) {
            if (state.tabs[tabIndex].sliders.items[slider1Index].value.items.length === 1) {
                commit('setSlider2ItemValue', {tabIndex, slider1Index, value: ''});
            } else {
                commit('removeSlider2Item', {tabIndex, slider1Index, slider2Index});
            }
        },
        saveJsonData({commit}, data) {
            commit('saveJsonData', {key: 'config', value: data.config});
            commit('saveJsonData', {key: 'tabs', value: data.tabs});
        }
    }
}