import CurrencyFormatter from "./utility/currencyFormatter.util";

// window.localeData added
window.localeData = {
    currentLocale: 'pl',
    currencySymbol: 'zł',
    currencyShortName: 'PLN',
    precision: 2,
    decimalSymbol: ',',
    pattern: '%1 %2',
    groupLength: 3,
    groupSymbol: ' '
};
window.strixCurrencyFormatter = new CurrencyFormatter(window.localeData || {});

