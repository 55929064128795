import template from "./template.html";
import SCalculatorAppTabsItem from "./SCalculatorAppTabsItem";

export default {
    name: "SCalculatorAppTabs",
    template,
    components: {
        SCalculatorAppTabsItem
    },
    props: {
        tabs: {
            type: Array,
            default() {
                return [];
            }
        },
        activeTabIndex: {
            type: Number,
            default: 0
        }
    },
    methods: {
        onTabClick(index) {
            this.$emit('onTabClick', index);
        }
    }
}
