<template>
  <b-container>
    <b-row>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="First feed option header">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Change default value" switch class="mr-n2 mb-n1"
                               @change="switchChange(changeFirstFeedOptionHeader, 'firstFeedOptionHeader')"
                               v-model="changeFirstFeedOptionHeader">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input
                :title="changeFirstFeedOptionHeader ? firstFeedOptionHeader : defaultDataConfig.firstFeedOptionHeader"
                :placeholder="defaultDataConfig.firstFeedOptionHeader"
                v-model="firstFeedOptionHeader"
                :disabled="!changeFirstFeedOptionHeader"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="First feed option value label">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Change default value" switch class="mr-n2 mb-n1"
                               @change="switchChange(changeFirstFeedOptionLabel, 'firstFeedOptionLabel')"
                               v-model="changeFirstFeedOptionLabel">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input :placeholder="defaultDataConfig.firstFeedOptionLabel"
                          :title="changeFirstFeedOptionLabel ? firstFeedOptionLabel : defaultDataConfig.firstFeedOptionLabel"
                          :disabled="!changeFirstFeedOptionLabel"
                          v-model="firstFeedOptionLabel"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="First feed option value"
                      invalid-feedback="Please enter value"
                      :state="firstFeedOptionValue !== ''">
          <b-input-group size="sm">
            <b-form-input :placeholder="defaultDataConfig.firstFeedOptionValue"
                          v-model="firstFeedOptionValue"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="Second feed option header">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Change default value" switch class="mr-n2 mb-n1"
                               @change="switchChange(changeSecondFeedOptionHeader, 'secondFeedOptionHeader')"
                               v-model="changeSecondFeedOptionHeader">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input
                :title="changeSecondFeedOptionHeader ? secondFeedOptionHeader : defaultDataConfig.secondFeedOptionHeader"
                :placeholder="defaultDataConfig.secondFeedOptionHeader"
                v-model="secondFeedOptionHeader"
                :disabled="!changeSecondFeedOptionHeader"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="Second feed option value label">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Change default value" switch class="mr-n2 mb-n1"
                               @change="switchChange(changeSecondFeedOptionLabel, 'secondFeedOptionLabel')"
                               v-model="changeSecondFeedOptionLabel">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input :placeholder="defaultDataConfig.secondFeedOptionLabel"
                          v-model="secondFeedOptionLabel"
                          :disabled="!changeSecondFeedOptionLabel"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="Second feed option value"
                      invalid-feedback="Please enter value"
                      :state="secondFeedOptionValue !== ''">
          <b-input-group size="sm">
            <b-form-input :placeholder="defaultDataConfig.secondFeedOptionValue"
                          v-model="secondFeedOptionValue">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group
            label="Hide second feed option">
          <b-input-group
              size="sm">
            <b-form-checkbox v-model="hideSecondFeedOption" name="check-button" switch>
            </b-form-checkbox>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group
            label="Show second feed option icon">
          <b-input-group
              size="sm">
            <b-form-checkbox v-model="showSecondFeedOptionIcon" name="check-button" switch>
            </b-form-checkbox>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="First Icon Label">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Change default value" switch class="mr-n2 mb-n1"
                               @change="switchChange(changeIconLabel1, 'iconLabel1')"
                               v-model="changeIconLabel1">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input :placeholder="defaultDataConfig.iconLabel1"
                          v-model="iconLabel1"
                          :disabled="!changeIconLabel1"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12" sm="6" lg="4" xl="3" class="mb-2">
        <b-form-group label="Second Icon Label">
          <b-input-group size="sm">
            <b-input-group-prepend is-text>
              <b-form-checkbox v-b-tooltip.hover title="Change default value" switch class="mr-n2 mb-n1"
                               @change="switchChange(changeIconLabel2, 'iconLabel2')"
                               v-model="changeIconLabel2">
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-form-input :placeholder="defaultDataConfig.iconLabel2"
                          v-model="iconLabel2"
                          :disabled="!changeIconLabel2"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import setComputedCodeDataConfig from '../helpers/set-computed-code-data-config';

export default {
  name: "CalculatorConfigurationNoSlidersView",
  data() {
    return {
      changeFirstFeedOptionHeader: false,
      changeFirstFeedOptionLabel: false,
      changeSecondFeedOptionHeader: false,
      changeSecondFeedOptionLabel: false,
      changeIconLabel1: false,
      changeIconLabel2: false
    }
  },
  computed: {
    ...mapState({
      defaultDataConfig: state => state.defaultData.config,
      codeDataConfig: state => state.codeData.config
    }),
    firstFeedOptionHeader: setComputedCodeDataConfig('firstFeedOptionHeader'),
    firstFeedOptionLabel: setComputedCodeDataConfig('firstFeedOptionLabel'),
    firstFeedOptionValue: setComputedCodeDataConfig('firstFeedOptionValue'),
    secondFeedOptionHeader: setComputedCodeDataConfig('secondFeedOptionHeader'),
    secondFeedOptionLabel: setComputedCodeDataConfig('secondFeedOptionLabel'),
    secondFeedOptionValue: setComputedCodeDataConfig('secondFeedOptionValue'),
    hideSecondFeedOption: setComputedCodeDataConfig('hideSecondFeedOption'),
    showSecondFeedOptionIcon: setComputedCodeDataConfig('showSecondFeedOptionIcon'),
    iconLabel1: setComputedCodeDataConfig('iconLabel1'),
    iconLabel2: setComputedCodeDataConfig('iconLabel2')
  },
  methods: {
    ...mapMutations('codeData', [
      'setConfig'
    ]),
    switchChange(checkboxValue, key, clearValue = '') {
      if (!checkboxValue) {
        this.setConfig({key, value: clearValue});
      }
    }
  }
}
</script>
