import Vue from 'vue';
import './components/StrixCurrencyFormatter/src/Resources/app/storefront/src/main';
import App from './App.vue';
import { BootstrapVue } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from './store';
import vmodal from 'vue-js-modal';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(vmodal);

new Vue({
  store,
  render: h => h(App),
}).$mount('#app');
